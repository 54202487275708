'use client';

import { useEffect } from 'react';
import { logger } from '@/lib/logger';
import Link from 'next/link';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    logger.error('Caught error in in global-error boundary', error);
  }, [error]);

  return (
    <html>
      <body>
        <div className="error-container">
          <h2>Something went wrong!</h2>
          <p>Error message: {error.message}</p>
          <div className="button-container">
            <button onClick={() => reset()}>Try again</button>
            <Link href={'/'}>
              <button>Home</button>
            </Link>
          </div>
        </div>
      </body>
    </html>
  );
}
